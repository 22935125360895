<template>
  <section>
    <!-- Condition false / Menu Burger  -->
    <b-button
      v-if="!isVerticalNavbar"
      v-b-toggle.sidebar-backdrop
      variant="dark"
      class="position-fixed m-2"
      style="right:0;top:0;"
    >
      <!-- Menu Burger  -->
      <b-icon icon="justify" variant="light" scale="3" />
      <!-- Menu Burger  -->
    </b-button>
    <!-- Condition false / Menu Burger  -->

    <aside :style="asideStyle" :class="asideClass">
      <!-- Condition false / NavBar pour mobile -->
      <b-sidebar
        id="sidebar-backdrop"
        backdrop-variant="dark"
        bg-variant="dark"
        text-variant="light"
        backdrop
        shadow
        no-header-close
        z-index="65535"
        width="250px"
        right
        v-if="!isVerticalNavbar"
      >
        <NavBar :isVerticalNavbar="true" />
        <template #footer="{ hide }">
          <b-button size="md" @click="hide" variant="danger" block>
            <!-- Fermeture NavBar -->
            <b-icon icon="x" variant="light" scale="3" />
            <!-- Fermeture NavBar -->
          </b-button>
        </template>
      </b-sidebar>
      <!-- Condition false / NavBar pour mobile -->

      <!-- Condition true / NavBar pour desktop -->
      <NavBar v-else :isVerticalNavbar="isVerticalNavbar" />
      <!-- Condition true / NavBar pour desktop -->
    </aside>
    <main>
      <b-container fluid>
        <transition name="smoothly-transition" mode="out-in">
          <!-- Affichage des vues du router -->
          <router-view />
          <!-- Affichage des vues du router -->
        </transition>
      </b-container>
    </main>
  </section>
</template>

<script>
export default {
  components: {
    NavBar: () => import('./components/NavBar'),
  },
  data() {
    return {
      innerHeight: 0,
      innerWidth: 0,
    }
  },
  computed: {
    isVerticalNavbar() {
      return this.innerWidth >= 992 ? true : false
    },
    asideClass() {
      const isTrue = ['pt-4', 'my-0', 'bg-dark']

      return this.innerWidth >= 992 ? isTrue : []
    },
    asideStyle() {
      const isTrue = {
        'min-height': this.innerHeight + 'px',
      }

      return this.innerWidth >= 992 ? isTrue : {}
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    window.dispatchEvent(new Event('resize'))
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.innerHeight = parseInt(window.innerHeight)
      this.innerWidth = parseInt(window.innerWidth)
    },
  },
}
</script>

<style lang="less">
aside,
main {
  width: 100%;
}

@media (min-width: 992px) {
  section {
    display: flex;
  }
  aside {
    min-width: 250px;
    max-width: 250px;
    margin: 0;
    padding: 0;
  }
}
</style>
